export const LISTEN_KEY = "LISTEN_KEY";
export const CURRENT_POSITION_INFORMATION = "CURRENT_POSITION_INFORMATION";
export const AVAILABLE_BALANCE = "AVAILABLE_BALANCE";
export const OPEN_ORDERS = "OPEN_ORDERS";

export default {
  [LISTEN_KEY]: (state) => state.data.listenKey,

  [CURRENT_POSITION_INFORMATION]: (state) => {
    var position = [];
    var data = state.data.positionInformation;
    data.forEach((e) => {
      if (Math.abs(e.positionAmt) > 0) {
        position.push(e);
      }
    });
    return position;
  },
  [AVAILABLE_BALANCE]: (state) => {
    return state.data.accountInformation.availableBalance;
  },

  [OPEN_ORDERS]: (state) => {
    return state.data.allOpenOrders;
  },
};
