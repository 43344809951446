import chart from "./chart";
import api from "./API";

export default {
  namespaced: true,
  modules: {
    chart: chart,
    api: api,
  },
};
