// import Vue from 'vue'
import * as user from "./types";
// import { filterType } from '../../../constant'

export default {
  [user.SET_LISTEN_KEY](state, key) {
    state.data.listenKey = key;
  },
  [user.SET_API_KEY](state, key) {
    state.data.API.APIKEY = key;
  },
  [user.SET_API_SECRET](state, key) {
    state.data.API.APISECRET = key;
  },
  [user.SET_API_CONNECTION](state, status) {
    state.data.APIConnection = status;
  },
  [user.SET_POSITION_INFORMATION](state, data) {
    state.data.positionInformation = data;
  },
  [user.SET_ACCOUNT_INFORMATION](state, data) {
    state.data.accountInformation = data;
  },
  [user.CHANGE_WALLET_BALANCE](state, data) {
    state.data.accountInformation.availableBalance = data;
  },

  [user.SET_OPEN_ORDERS](state, data) {
    state.data.allOpenOrders = data;
  },
  [user.PUSH_OPEN_ORDERS](state, data) {
    state.data.allOpenOrders.push(data);
  },

  [user.DELETE_OPEN_ORDER](state, data) {
    for (var i = 0; i < state.data.allOpenOrders.length; i++) {
      if (state.data.allOpenOrders[i].orderId === data) {
        state.data.allOpenOrders.splice(i, 1);
      }
    }
  },
};
