export default {
  data: {
    user: null,
    userData: null,
    token: "",
    claims: null,
    firebaseLoaded: false,
  },
  views: {
    // currentFilter: 'All'
  },
};
