import * as streams from "./types";

// context === {dispatch: ƒ, commit: ƒ, getters: {…}, state: {…}, rootGetters: {…},…}
export default {
  [streams.PUSH_TRADE_DATA]({ commit }, payload) {
    commit(streams.PUSH_TRADE_DATA, payload);
  },

  [streams.SET_TICKER_DATA]({ commit }, payload) {
    commit(streams.SET_TICKER_DATA, payload);
  },
  [streams.SET_MARK_DATA]({ commit }, payload) {
    commit(streams.SET_MARK_DATA, payload);
  },

  [streams.PUSH_USER_DATA]({ commit }, payload) {
    commit(streams.PUSH_USER_DATA, payload);
  },

  // [streams.CHANGE_CURRENT_FILTER_TO_ACTIVE] ({ commit }) {
  //   commit(streams.CHANGE_CURRENT_FILTER_TO_ACTIVE)
  // }
};
