// import Vue from 'vue'
import * as streams from "./types";
// import { filterType } from '../../../constant'

export default {
  [streams.PUSH_TRADE_DATA](state, data) {
    if (state.data.trade.length > 20) {
      state.data.trade.splice(-1, 1);
    }
    state.data.trade.unshift(data);
  },

  [streams.SET_TICKER_DATA](state, data) {
    state.data.ticker = data;
  },

  [streams.PUSH_USER_DATA](state, data) {
    if (state.data.userData.length > 20) {
      state.data.userData.splice(-1, 1);
    }
    state.data.userData.unshift(data);
  },

  [streams.SET_MARK_DATA](state, data) {
    state.data.mark = data;
  },

  // [streams.CHANGE_CURRENT_FILTER_TO_ALL] (state) {
  //   Vue.set(state.views, 'currentFilter', filterType.ALL)
  //   // state.views.currentFilter = "All";
  // },

  // [streams.CHANGE_CURRENT_FILTER_TO_DONE] (state) {
  //   Vue.set(state.views, 'currentFilter', filterType.DONE)
  //   // state.views.currentFilter = "Done";
  // },

  // [streams.CHANGE_CURRENT_FILTER_TO_ACTIVE] (state) {
  //   Vue.set(state.views, 'currentFilter', filterType.ACTIVE)
  //   // state.views.currentFilter = "Active";
  // }
};
