export const FIREBASE_LOADED = "FIREBASE_LOADED";
export const GET_USER = "GET_USER";
export const GET_USER_DATA = "GET_USER_DATA";
export const GET_CLAIMS = "GET_CLAIMS";
export const GET_TOKEN = "GET_TOKEN";

export default {
  [FIREBASE_LOADED](state) {
    return state.data.firebaseLoaded;
  },
  [GET_USER](state) {
    return state.data.user;
  },
  [GET_CLAIMS](state) {
    return state.data.claims;
  },
  [GET_TOKEN](state) {
    return state.data.token;
  },
};
