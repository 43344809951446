import Vue from "vue";
import axios from "axios";
import store from "../store";

const firebaseAPI = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? "https://us-west2-woozlabs.cloudfunctions.net/"
      : "http://localhost:5001/woozlabs/us-west2/",
  timeout: 10000,
  headers: { "X-Custom-Header": "foobar" },
});

firebaseAPI.interceptors.request.use(
  function (config) {
    config.headers.authorization = store.getters["auth/user/GET_TOKEN"];
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

Vue.prototype.$axios = firebaseAPI;
