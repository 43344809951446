import stream from "./stream";
import user from "./user";

export default {
  namespaced: true,
  modules: {
    stream: stream,
    user: user,
  },
};
