// import Vue from 'vue'
import * as types from "./types";
// import { filterType } from '../../../constant'

export default {
  [types.SET_USER](state, user) {
    state.data.user = user;
  },
  [types.SET_USER_DATA](state, data) {
    state.data.userData = data;
  },
  [types.SET_TOKEN](state, token) {
    state.data.token = token;
  },

  [types.SET_CLAIMS](state, claims) {
    state.data.claims = claims;
  },

  [types.SET_FIREBASE_LOADED](state) {
    state.data.firebaseLoaded = true;
  },
};
