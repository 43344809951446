// namespace
export const NAMESPACE = "streams";

// actions
export const PUSH_TRADE_DATA = "PUSH_TRADE_DATA";
export const SET_TICKER_DATA = "SET_TICKER_DATA";
export const PUSH_USER_DATA = "PUSH_USER_DATA";
export const SET_MARK_DATA = "SET_MARK_DATA";

// export const CHANGE_CURRENT_FILTER_TO_ALL = 'CHANGE_CURRENT_FILTER_TO_ALL'
// export const CHANGE_CURRENT_FILTER_TO_DONE = 'CHANGE_CURRENT_FILTER_TO_DONE'
// export const CHANGE_CURRENT_FILTER_TO_ACTIVE =
//   'CHANGE_CURRENT_FILTER_TO_ACTIVE'
