import binance from "./binance";
import preferance from "./preferance";

export default {
  namespaced: true,
  modules: {
    binance: binance,
    preferance: preferance,
  },
};
