export default {
  data: {
    API: {
      // APIKEY: 'vxMmqvXvPxUhs96z8NQmJksppGeFYdVtrUZjmSFWqi5RjiCWbJpBPfcuGjCYksRT',
      // APISECRET: 'IKVQJGzs49b0eXzb3QNgVKYrHZohjm2rXqEDYMot9XS4RLWyVNpNxIqJV7gfZVIg',
      APIKEY: "",
      APISECRET: "",
    },

    APIConnection: false,
    listenKey: "",
    positionInformation: [
      // {
      //   entryPrice: '40000.1403',
      //   marginType: 'isolated',
      //   isAutoAddMargin: 'false',
      //   isolatedMargin: '0.00000000',
      //   leverage: '10',
      //   liquidationPrice: '0',
      //   markPrice: '6679.50671178',
      //   maxNotionalValue: '20000000',
      //   positionAmt: '-2.10',
      //   symbol: 'BTCUSDT',
      //   unRealizedProfit: '0.00000000',
      //   positionSide: 'SHORT',
      //   updateTime: 0
      // }
    ],
    accountInformation: {},
    allOpenOrders: [
      //       avgPrice: "0.00000"
      // clientOrderId: "JhMTdf1KKazpf0FxB4jCIH"
      // closePosition: false
      // cumQty: "0"
      // cumQuote: "0"
      // executedQty: "0"
      // orderId: 27033133210
      // origQty: "0.001"
      // origType: "LIMIT"
      // positionSide: "LONG"
      // price: "38000"
      // priceProtect: false
      // reduceOnly: false
      // side: "BUY"
      // status: "CANCELED"
      // stopPrice: "0"
      // symbol: "BTCUSDT"
      // timeInForce: "GTC"
      // type: "LIMIT"
      // updateTime: 1627544090945
      // workingType: "CONTRACT_PRICE"
    ],
  },
  views: {},
};
