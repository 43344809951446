import * as types from "./types";
import Vue from "vue";
// context === {dispatch: ƒ, commit: ƒ, getters: {…}, state: {…}, rootGetters: {…},…}
export default {
  async [types.GET_USER]({ commit, dispatch }, user) {
    commit(types.SET_USER, user);
    if (!user) {
      commit(types.SET_FIREBASE_LOADED);
      return null;
    }
    try {
      // await dispatch(user.VISIT_USER);
    } catch (e) {
      console.log(e.message);
    }
    await dispatch(types.GET_TOKEN);
    commit(types.SET_FIREBASE_LOADED);
    return true;
  },

  async [types.VISIT_USER]({ state }) {
    if (!state.user.displayName) return false;
    const increment = Vue.prototype.$firebase.firestore.FieldValue.increment(1);
    await Vue.prototype.$firebase
      .firestore()
      .collection("users")
      .doc(state.user.uid)
      .update({
        displayName: state.user.displayName,
        visitedAt: new Date(),
        visitCount: increment,
      });
    return true;
  },
  async [types.GET_TOKEN]({ commit, state }) {
    const token = await state.data.user.getIdToken(true);
    commit(types.SET_TOKEN, token);
    const { claims } = await state.data.user.getIdTokenResult();
    commit(types.SET_CLAIMS, claims);
  },
};
