export const ACTIVE_INTERVAL_SORT = "ACTIVE_INTERVAL_SORT";
function sorter(a, b) {
  if (a < b) return -1; // any negative number works
  if (a > b) return 1; // any positive number works
  return 0; // equal values MUST yield zero
}
export default {
  [ACTIVE_INTERVAL_SORT](state) {
    var arr = state.views.activeInterval.sort(sorter);
    return arr;
  },
};
