import Vue from "vue";
import ToastedPlugin from "vue-toasted";

Vue.use(ToastedPlugin, {
  iconPack: "mdi",
});

Vue.toasted.register(
  "notice",
  (payload) => {
    return payload;
  },
  {
    icon: "mdi-bell",
    position: "bottom-right",
    keepOnHover: "true",
    type: "info",
    duration: 4000,
    // action: {
    //   text: '닫기',
    //   onClick: (e, toastObject) => {
    //     toastObject.goAway(0)
    //   }
    // }
  },
);
Vue.toasted.register(
  "success",
  (payload) => {
    return payload;
  },
  {
    position: "bottom-right",
    icon: "mdi-check-circle-outline",
    type: "success",
    keepOnHover: "true",
    duration: 4000,
  },
);

Vue.toasted.register(
  "error",
  (payload) => {
    return payload;
  },
  {
    icon: "mdi-alert",
    position: "bottom-right",
    type: "error",
    duration: 5000,
    keepOnHover: "true",
    fitToScreen: "true",
    //   action: {
    //     class: 'white--text',
    //     icon: 'mdi-close',
    //     onClick: (e, toastedObject) => {
    //       toastedObject.goAway(0)
    //     }
    //   }
  },
);
