// import Vue from 'vue'
// import Vuex from 'vuex'

// import Futures from './futures'
// Vue.use(Vuex)

// export default new Vuex.Store({
//   modules: {
//     futures: Futures
//   }
// })
import futures from "./futures";

export default {
  namespaced: true,
  modules: {
    futures: futures,
  },
};
