// namespace
export const NAMESPACE = "listenKey";

// actions
export const SET_LISTEN_KEY = "SET_LISTEN_KEY";
export const SET_API_KEY = "SET_API_KEY";
export const SET_API_SECRET = "SET_API_SECRET";
export const SET_API_CONNECTION = "SET_API_CONNECTION";

export const SET_POSITION_INFORMATION = "SET_POSITION_INFORMATION";
export const SET_ACCOUNT_INFORMATION = "SET_ACCOUNT_INFORMATION";

export const CHANGE_WALLET_BALANCE = "CHANGE_WALLET_BALANCE";

export const SET_OPEN_ORDERS = "SET_OPEN_ORDERS";
export const PUSH_OPEN_ORDERS = "PUSH_OPEN_ORDERS";
export const DELETE_OPEN_ORDER = "DELETE_OPEN_ORDER";
