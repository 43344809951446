// import Vue from 'vue'
import * as user from "./types";
// import { filterType } from '../../../constant'

export default {
  [user.SET_LANGUAGE](state, language) {
    state.data.language = language;
  },
  [user.SET_THEME](state, theme) {
    state.data.theme = theme;
  },
  [user.SET_SHOW_NOTICE](state, status) {
    state.data.showNotice = status;
  },
};
