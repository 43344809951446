export const RECENT_TRADE = "RECENT_TRADE";
export const CURRENT_TICKER = "CURRENT_TICKER";

// function numberFilter (obj) {
//   if ('value' in obj && (typeof (obj.salary) === 'number') && !isNaN(obj.salary) && obj.salary > 0) {
//     return true
//   } else {
//     return false
//   }
// }

export default {
  [RECENT_TRADE](state) {
    if (state.data.trade.length > 0) {
      return state.data.trade[0];
    }
  },
  [CURRENT_TICKER](state) {
    return state.data.ticker;
  },
};
