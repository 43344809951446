export default {
  data: {
    trade: [],
    ticker: {},
    mark: {},
    userData: [],
  },
  // trade: [],
  // ticker: [],
  // userData: [],
  views: {
    // currentFilter: 'All'
  },
};
