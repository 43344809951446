<template>
  <v-app>
    <v-main class="background">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import "./styles";
export default {
  // eslint-disable-next-line vue/no-unused-components
  name: "App",

  data: () => ({
    //
  }),
  mounted() {
    this.setLocale();
    this.setTheme();
  },
  methods: {
    setTheme() {
      if (window && window.matchMedia("(prefers-color-scheme: dark)").matches) {
        this.$analyticsLog(this.$analytics, "initial_theme", { theme: "dark" });
        this.$vuetify.theme.dark = true;
      } else {
        this.$analyticsLog(this.$analytics, "initial_theme", {
          theme: "light",
        });
        this.$vuetify.theme.dark = false;
      }
    },
    setLocale() {
      if (this.$store.getters["page/settings/CURRENT_LANGUAGE"]) {
        this.$i18n.locale =
          this.$store.getters["page/settings/CURRENT_LANGUAGE"];
      } else {
        var lang = "un"; // 언어 값 받아올 변수. un은 undefined 의 앞 2글자.

        if (navigator.language != null) {
          lang = navigator.language;
        } else if (navigator.userLanguage != null) {
          // 이 값이 null이 아니면. 즉 익스라면
          lang = navigator.userLanguage;
        } else if (navigator.systemLanguage != null) {
          // 여기까지는 안 올거 같은데 혹시나 해서
          lang = navigator.systemLanguage;
        } else {
          // 이도저도 아니면
          lang = "un";
        }

        lang = lang.toLowerCase(); // 받아온 값을 소문자로 변경
        lang = lang.substring(0, 2); // 소문자로 변경한 갚의 앞 2글자만 받아오기
        this.$store.dispatch("page/settings/SET_LANGUAGE", lang);
        this.$i18n.locale = lang;
      }
    },
  },
};
</script>

<style></style>
