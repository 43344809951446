/* eslint-disable no-unused-vars */
import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const pageLogWrite = (to) => {
  if (store.getters["auth/user/GET_USER"]) {
    const { uid, email } = store.getters["auth/user/GET_USER"];
    Vue.prototype.$firebase
      .firestore()
      .collection("pageLogs")
      .add({
        uid,
        email,
        to: to.path,
        createdAt: new Date(),
      })
      .catch((e) => console.log(e.message));
  } else {
    Vue.prototype.$firebase
      .firestore()
      .collection("pageLogs")
      .add({
        uid: "Anonymous",
        email: "-",
        to: to.path,
        createdAt: new Date(),
      })
      .catch((e) => console.log(e.message));
  }
};

const levelCheck = (level) => {
  return (to, from, next) => {
    if (!store.getters["auth/user/GET_USER"]) {
      if (level < 3) {
        return next("/404");
      }
      if (to.path !== "/login") {
        if (to.path !== "/") {
          Vue.prototype.$toasted.global.error("로그인이 필요합니다.");
        }
        return next("/login");
      }
    } else {
      if (store.getters["auth/user/GET_CLAIMS"] === null)
        return next("/page/mypage");
      // if (store.state.auth.claims.level > level) throw Error(msg[level])
      if (store.getters["auth/user/GET_CLAIMS"].level > level)
        return next("/404");
      pageLogWrite(to);
    }
    next();
  };
};

export const routes = [
  {
    path: "/",
    component: () => import("../views/Demo/Trading/Home.vue"),
  },

  /**
   * AUTHORIZATION
   */
  {
    path: "/login",
    name: "Login",
    beforeEnter: (to, from, next) => {
      if (store.state.auth.user.data.user) return next("/");
      next();
    },
    component: () => import("../views/Authorization/LoginPage.vue"),
  },
  {
    path: "/logout",
    name: "Logout",
    beforeEnter: levelCheck(3),
    component: () => import("../views/Authorization/LogoutPage.vue"),
  },
  {
    path: "/404",
    name: "404",
    component: () => import("../views/404.vue"),
  },
  {
    path: "*",
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes,
});

const waitFirebase = () => {
  return new Promise((resolve, reject) => {
    let cnt = 0;
    const timer = setInterval(() => {
      if (store.getters["auth/user/FIREBASE_LOADED"]) {
        clearInterval(timer);
        resolve();
      } else if (cnt++ > 500) {
        clearInterval(timer);
        reject(Error("서버 연결을 실패했습니다."));
      }
    }, 10);
  });
};
router.beforeEach((to, from, next) => {
  if (to.name === "") {
    alert("준비중입니다.");
    return;
  }
  waitFirebase()
    .then(() => next())
    .catch((e) => Vue.prototype.$toasted.global.error(e.message));
});

router.afterEach((to, from) => {
  // Vue.prototype.$Progress.finish();
});
// // Router Error 처리
router.onError((e) => {
  Vue.prototype.$toasted.global.error(e.message);
});
export default router;
