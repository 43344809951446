/* eslint-disable no-unused-vars */
import Vue from "vue";
import Vuex from "vuex";

import Trading from "./modules/trading";
import authorization from "./modules/authorization";
import page from "./modules/page";

import createPersistedState from "vuex-persistedstate";
import Cookies from "js-cookie";
Vue.use(Vuex);

export default new Vuex.Store({
  namespaced: true,
  modules: {
    trading: Trading,
    auth: authorization,
    page: page,
  },

  plugins: [
    // createPersistedState({
    //   key: "trading_chart",
    //   paths: ["trading.preferance.system.chart"],
    //   storage: {
    //     getItem: (key) => Cookies.get(key),
    //     setItem: (key, value) => {
    //       Cookies.set(key, value, { expires: 7, secure: true });
    //     },
    //     removeItem: (key) => Cookies.remove(key),
    //   },
    // }),
    // createPersistedState({
    //   key: "binance_lk",
    //   paths: ["trading.binance.futures.user.data.listenKey"],
    //   storage: {
    //     getItem: (key) => Cookies.get(key),
    //     setItem: (key, value) => {
    //       Cookies.set(key, value, { expires: 1 / 24, secure: true });
    //     },
    //     removeItem: (key) => Cookies.remove(key),
    //   },
    // }),

    // createPersistedState({
    //   key: "binance_api",
    //   paths: ["trading.binance.futures.user.data.API"],
    //   storage: {
    //     getItem: (key) => Cookies.get(key),
    //     setItem: (key, value) => {
    //       Cookies.set(key, value, { expires: 1 / 24, secure: true });
    //     },
    //     removeItem: (key) => Cookies.remove(key),
    //   },
    // }),

    createPersistedState({
      key: "page",
      paths: ["page.settings.data"],
      storage: {
        getItem: (key) => Cookies.get(key),
        setItem: (key, value) => {
          Cookies.set(key, value, { expires: 1, secure: true });
        },
        removeItem: (key) => Cookies.remove(key),
      },
    }),
  ],
});
