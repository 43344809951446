import * as user from "./types";

// context === {dispatch: ƒ, commit: ƒ, getters: {…}, state: {…}, rootGetters: {…},…}
export default {
  [user.SET_LISTEN_KEY]({ commit }, payload) {
    commit(user.SET_LISTEN_KEY, payload);
  },
  [user.SET_API_KEY]({ commit }, payload) {
    commit(user.SET_API_KEY, payload);
  },
  [user.SET_API_SECRET]({ commit }, payload) {
    commit(user.SET_API_SECRET, payload);
  },
  [user.SET_API_CONNECTION]({ commit }, payload) {
    commit(user.SET_API_CONNECTION, payload);
  },
  [user.SET_POSITION_INFORMATION]({ commit }, payload) {
    commit(user.SET_POSITION_INFORMATION, payload);
  },
  [user.SET_ACCOUNT_INFORMATION]({ commit }, payload) {
    commit(user.SET_ACCOUNT_INFORMATION, payload);
  },
  [user.CHANGE_WALLET_BALANCE]({ commit }, payload) {
    commit(user.CHANGE_WALLET_BALANCE, payload);
  },

  [user.SET_OPEN_ORDERS]({ commit }, payload) {
    commit(user.SET_OPEN_ORDERS, payload);
  },
  [user.PUSH_OPEN_ORDERS]({ commit }, payload) {
    commit(user.PUSH_OPEN_ORDERS, payload);
  },
  [user.DELETE_OPEN_ORDER]({ commit }, payload) {
    commit(user.DELETE_OPEN_ORDER, payload);
  },
};
